@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

html, body {
    font-family: 'Roboto','Helvetica','Arial','sans-serif';
    background-color: azure;
	letter-spacing: .01071em;
}

.desktop-page {
	position: absolute;
	left: 300px;
	top: 0;
	width: calc((100vw - 300px) / 2);
	overflow: hidden;
}

.desktop-modals {
	position: absolute;
	right: 0;
	top: 0;
	width: calc((100vw - 300px) / 2);
	overflow: hidden;
	height: 100vh;
	z-index: 110;
}

	.desktop-modals.reports {
		width: calc((100vw - 300px) / 4 * 3);
	}

.page, .page.login {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
	width: 800px;
    max-width: 100vw;
    overflow: hidden;
}

.page {
    background-position-x: -125px;
    background-position-y: -75px;
    background-attachment: fixed;
}

	.page .content {
		padding-top: 1vh;
		padding-bottom: 8vh;
	}

.tutorial {
	display: flex;
	padding-top: 3vh;
	padding-bottom: 3vh;
}

.info {
	min-height: 10vh;
	border: 1px solid;
	padding: 2vh 2vh 0 2vh;
	width: 90%;
	margin: auto;
	max-height: 70vh;
	overflow-y: auto;
	scrollbar-width: thin;
}
	
	.info::-webkit-scrollbar {
		display: none;
	}

.info-button {
	position: absolute;
	top: 90vh;
}

.start-hint {
	height: auto !important;
	width: 100%;
	cursor: pointer;
	padding-left: 12px !important;
	padding-right: 12px !important;
	margin-top: 1vh;
	margin-bottom: 1vh;
}

.edit-page {
	min-height: 10vh;
	padding: 2vh 2vh 0 2vh;
	width: 100%;
	margin: auto;
	height: 95vh;
	overflow-y: auto;
	scrollbar-width: thin;
}

.desktop-modals .edit-page {
	height: 100vh;
}

.page.login {
    background-size: auto 100%;
    background-attachment: fixed;
}

    .page.login .btn-large {
        height: 10vh;
    }

    .page.login .footer {
        width: 100%;
        position: absolute;
        top: 85vh;
        left: 0;
    }

    .page.login .foot-label {
        font-size: 2vh;
        width: 100%;
        color: black;
    }

    .page.login .btn {
        margin-bottom: 3vh;
    }

	.page.login .mt-20, .wiz .mt-20 {
		margin-top: 10vh !important;
	}

	.page.login .text-sm-justify {
		text-align: justify;
		font-size: 2vh;
		font-weight: normal;
		margin: 1vh 4% 0 4%;
	}

	.page.login .text-sm-center {
		font-size: 2vh;
	}

	.page.login h5 {
		font-weight: bolder;
		text-transform: capitalize;
	}

	.page.login .page-grid {
		height: 100vh;
		width: 100vw;
		align-content: center;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
	}

.content {
    height: 92vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-attachment: fixed;
    position: relative;
}

.desktop-page .content {
	height: 100vh;
}

h2, h3 {
	font-weight: bold;
}

h3 {
	font-size: 1.17em;
}

h1 {
    font-size: 2em;
}

h1:focus {
    outline: none;
}

a, .btn-link {
    color: #0071c1;
}

.btn, .modal-info-header {
    font-weight: bold;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.valid.modified:not([type=checkbox]) {
    outline: 1px solid #26b050;
}

.invalid {
    outline: 1px solid red;
}

.validation-message {
    color: red;
}

td {
    text-align: center;
}

.nav-link, .nav-link:focus, .nav-link:hover {
    color: white;
    font-weight: bold;
	font-size: 1.3rem;
}

    .nav-link.active, .nav-link.active:focus, .nav-link.active:hover {
        color: rgb(120 211 177);
    }

.highlighted {
	animation: glow 1s infinite;
}

@keyframes glow {
	0%, 100% {
		box-shadow: 0 0 20px 5px;
	}
	50% {
		box-shadow: 0 0 10px 1px;
	}
}

.navbar-toggler {
    color: rgb(255 255 255);
    position: fixed;
    z-index: 2;
    top: 1vh;
    right: 1vw;
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
    padding: 5px;
}

.btn-large {
    width: 100%;
}

.btn, .form-control, .form-select, .modal-dialog, .MuiInputBase-root, .MuiButtonBase-root {
    border-radius: 15px !important;
}

.form-label {
    margin-bottom: .5rem;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-5.mb-0 {
	margin-bottom: 0px !important;
}

.btn-add {
	font-size: 2rem !important;
	min-width: 0px !important;
	padding: 0px !important;
	height: 38px;
}

	.btn-budget .back-bar {
		clear: both;
		float: none;
		height: 10px;
		width: 100%;
		background-image: linear-gradient(-90deg, rgb(0 97 155) 0%, rgb(120 211 177) 100%);
	}

	.btn-budget .white-bar {
		float: right;
		background-color: white;
		height: 100%;
	}

	.btn-budget .red-bar {
		float: right;
		background-color: red;
		height: 100%;
	}

.btn-total {
	margin-top: -5px !important;
	margin-left: -12px !important;
	margin-right: -12px !important;
	width: calc(100% + 24px) !important;
	border-radius: 0 0 25px 25px !important;
	background-color: rgb(0 97 155) !important;
	color: azure !important;
	margin-bottom: 0px !important;
}

.budget-container {
	background-color: aliceblue !important;
    padding-bottom: 0px !important;
    border-radius: 25px;
}

.summary-header {
	border-radius: 25px 25px 0 0;
	padding-top: 16px;
	padding-bottom: 16px;
}

.list-header {
	position: fixed;
	height: 80px;
	background-color: azure;
	z-index: 100;
}

.desktop-page .list-header {
	width: calc((100vw - 300px) / 2 - 45px);
}

.page .list-header {
	width: 800px;
	max-width: 100vw;
}

.list-body {
	padding-top: 80px;
}

.budget-list {
	background-color: white;
	margin-left: -12px !important;
	margin-right: -12px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	border-radius: 25px 25px 0 0;
}

.btn-list {
    width: 100%;
    margin-bottom: 5px;
    padding: 10px !important;
	cursor: pointer;
}

    .btn-list.btn-budget {
        padding-bottom: 30px !important;
        margin-top: -20px !important;
    }

	.btn-list .right {
		text-align: right;
	}

    .desktop-page .btn-list:not(.btn-budget):not(.btn-report) {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }

.w-color-circle {
	width: 200px;
	margin-bottom: 20px;
}

.mud-treeview {
	overflow: visible !important;
}

.text-inherit {
	font-size: inherit !important;
}

.mud-grid-item .no-dup {
	display: none;
}

.mud-table {
	box-shadow: none !important;
}

.mud-table-cell {
	padding: 0px !important;
	border-bottom: 0px !important;
	display: block !important;
}

.mud-table-row {
	display: block !important;
}

.mud-table-container::-webkit-scrollbar {
	display: none;
}

.section-divider {
    height: 0;
    border-top: 1px solid #DDD;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

    .section-divider > span {
        background: azure;
        display: inline-block;
        position: relative;
        padding: 0 17px;
        top: -13px;
        font-size: 15px;
    }

    .desktop-page .section-divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }

.hidden, .collapse:not(.show) {
	display: none !important;
}

.clear {
    clear: both;
}

.left {
    float: left;
}

.right {
    float: right;
}

.half {
    width: 48%;
}

.top30 {
    margin-top: 30px
}

.centered {
    margin: auto;
    text-align: center;
}

    .centered td {
        padding: 10px;
    }

.img-title {
    max-height: 25vh;
    display: block;
    margin: auto;
    margin-top: 4vh;
    margin-bottom: 3vh;
    object-fit: contain;
}

.img-zoom {
	max-width: 100%;
	max-height: 600px;
}

.iiz, .iiz div {
	width: 100%;
}

.iiz div {
	text-align: center;
}

.iiz div img {
	max-height: 600px;
	max-width: 100%;
	margin: auto;
}

.mr-10 {
    margin-right: 10px;
}

.px-4 {
	padding-left: 16px !important;
	padding-right: 16px !important;
}

.border {
    padding: 10px 0px;
}

.bg-app {
	background-color: rgb(0 97 155) !important /*00619b*/;
}

.text-app {
	color: rgb(0 97 155) !important;
}

.border-app, .border-app .MuiInputBase-root {
    border-color: rgb(0 97 155) !important;
    background-color: white;
}

.budget-container, .budget-summary-container, .btn-list.border-app:not(.btn-budget) {
	border-radius: 25px !important;
}

.btn-budget {
	padding-left: 20px !important;
	padding-right: 20px !important;
	box-shadow: 0 -1px 10px rgb(0 97 155);
	border-radius: 25px 25px 0 0 !important;
}

.fab {
	position: fixed !important;
	bottom: 10vh;
	right: 2vh;
	border-radius: 50% !important;
}

.border-success {
	border-color: rgb(120 211 177) /*78d3b1*/ !important;
}

.bg-success {
    background-color: rgb(120 211 177) !important;
}

.text-success {
    color: rgb(120 211 177) !important;
}

.text-white {
	color: white !important;
}

.border-none, .border-none fieldset {
	border: 0px;
	border-bottom: 1px solid;
	border-radius: 0px;
	padding: 0px;
}

	.border-none .MuiInputBase-root {
		background-color: transparent;
	}

.tooltip-info {
	background-color: #dddddd !important;
	color: rgb(0 97 155) !important;
}

.tooltip-success {
	background-color: rgb(120 211 177) !important;
	color: #ffffff !important;
}

.tooltip-error {
	background-color: red !important;
	color: #ffffff !important;
}

.arrow-info {
	color: #dddddd !important;
}

.arrow-success {
	color: rgb(120 211 177) !important;
}

.arrow-error {
	color: red !important;
}

@supports (-webkit-touch-callout: none) {
    .status-bar-safe-area {
        display: flex;
        position: sticky;
        top: 0;
        height: env(safe-area-inset-top);
        background-color: #f7f7f7;
        width: 100%;
        z-index: 1;
    }

	.flex-column, .navbar-brand {
		padding-left: env(safe-area-inset-left);
	}
}

.content_grid {
	background-image: url("../images/header.png");
	background-size: 100% 100%;
}

.topbar {
	font-weight: bold;
	height: 8vh;
	background-color: transparent !important;
	background-image: url("../images/header.png");
	background-size: 100% 100%;
	box-shadow: none !important;
	z-index: 101;
}

.MuiAppBar-root {
	background-color: transparent !important;
	box-shadow: none !important;
}

.top-leftbar {
	width: 100%;
	font-weight: bold;
	height: 50px;
	padding: 0px 25px;
	/*
	background-image: url("../images/header.png");
	background-image: linear-gradient(0deg, rgb(0 97 155) 0%, rgb(120 211 177) 100%);
*/
	background-size: 100% 100%;
	background-color: #00000000 !important;
	box-shadow: none !important;
}

	.top-leftbar .brandbar {
		height: 100%;
	}

	.top-leftbar .navbar-brand {
		display: flex;
	}

	.top-leftbar .logo {
		height: 4vh;
		width: 4vh;
		transform: translate(-50%, 0);
		background-image: url('../images/logo.png');
		background-size: 100% 100%;
		background-attachment: fixed;
	}

.top-bar {
	width: 100%;
	font-weight: bold;
	height: 50px;
	background-size: 100% 100%;
	box-shadow: none !important;
}

	.top-leftbar .brandbar {
		height: 100%;
	}

	.top-leftbar .navbar-brand {
		display: flex;
	}

	.top-leftbar .logo {
		height: 4vh;
		width: 4vh;
		transform: translate(-50%, 0);
		background-image: url('../images/logo.png');
		background-size: 100% 100%;
		background-attachment: fixed;
	}


.sidebar {
    background-image: linear-gradient(180deg, rgb(0 97 155) 0%, rgb(120 211 177) 100%);
}

.leftbar {
	background-image: linear-gradient(180deg, rgb(0 97 155) 0%, rgb(120 211 177) 100%);
}

.bottombar {
    background-image: linear-gradient(0deg, rgb(0 97 155) 0%, rgb(120 211 177) 100%);
	position: fixed;
	bottom: 0;
	height: 8vh;
	width: 100%;
	z-index: 1000;
}

.mud-appbar .mud-toolbar-appbar {
	height: 100% !important;
}

.back-button {
	position: absolute;
	top: 3vh;
	left: 7%;
	font-size: 5vh;
	font-weight: bolder;
}

.modal-container {
	display: none;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.modal-overlay {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 3;
	background-color: rgba(0,0,0,0.5);
}

.modal-active {
	display: flex;
}

.modal-dialog {
	z-index: 4;
	border: 2px solid;
	pointer-events: all;
	max-width: auto;
}

.modal-info-header {
	border-bottom: 1px solid;
	margin-bottom: 3vh;
	padding-bottom: 1vh;
}

.modal-info-body {
	text-align: justify;
}

.modal-info-footer {
	align-content: center;
	margin-top: 3vh;
}

.modal-header {
	position: fixed;
	top: -20px;
	margin-left: -20px;
	width: 100% !important;
	background-color: white;
	/*	background-image: linear-gradient(180deg, rgb(0 97 155) 0%, rgb(120 211 177) 100%);*/
	padding: 20px;
	z-index: 2;
}

.modal-body {
	padding-bottom: 80px;
	padding-top: 80px;
}

.modal-footer {
	position: fixed;
	bottom: 0px;
	margin-left: -20px;
	width: 100% !important;
	background-color: white;
	/*	background-image: linear-gradient(0deg, rgb(0 97 155) 0%, rgb(120 211 177) 100%);*/
	padding: 20px;
	z-index: 2;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 100vw;
	}

	.modal-header {
		max-width: calc(((100vw - 300px) / 2) - 20px);
	}

	.modal-footer {
		max-width: calc(((100vw - 300px) / 2) - 20px);
	}
}

.modals {
	position: fixed;
	top: 0;
	left: 0;
	background-color: azure;
	height: 100vh;
	z-index: 1000;
	width: 100%;
}

.desktop-modals .wiz {
	position: absolute;
	left: 10px;
	max-width: calc(((100vw - 300px) / 2) - 10px);
	box-shadow: rgb(0 97 155) -3px 0 10px 1px;
	width: 100%;
}

.desktop-modals.reports .wiz {
    max-width: calc(((100vw - 300px) / 4 * 3) - 10px);
}

	.wiz.edit {
		background-color: white;
	}

	.wiz.active {
		animation: .5s fadeIn;
		animation-fill-mode: forwards;
	}

	.wiz .MuiIconButton-root {
		font-size: 1.7rem !important;
	}

	.wiz h1 {
		font-weight: bold !important;
	}

.report-total {
	margin-bottom: 50px;
	text-align: end;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		left: 100%;
	}

	100% {
		opacity: 1;
		left: 10px;
	}
}

.report {
	width: 100%;
	margin-bottom: 50px;
	font-size: 13px !important;
}

	.report td, .report th {
		font-size: 13px !important;
		text-align: start;
		padding: 5px;
	}

	.report.full-page td {
		white-space: normal !important;
	}

.map-container {
	height: 400px;
	width: 100%;
	position: relative;
}

.map-bar {
	background-color: rgb(35 55 75 / 90%);
	color: #fff;
	padding: 6px 12px;
	position: absolute;
	top: 80px;
	left: 0;
	margin: 12px;
	border-radius: 4px;
}

.truck {
	width: 20px;
	height: 20px;
	border: 2px solid #fff;
	border-radius: 50%;
	background: #3887be;
	pointer-events: none;
}

.page-break-before {
	page-break-before: always;
	display: block;
}

.page-break-before {
	page-break-after: always;
	display: block;
}

.cursor-pointer {
	cursor: pointer;
}

.navbar-brand {
	font-size: 1.1rem;
}

.nav-item {
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
}

    .nav-item:first-of-type {
        padding-top: 1rem;
    }

    .nav-item:last-of-type {
        padding-bottom: 1rem;
    }

    .nav-item a {
        color: #d7d7d7;
        border-radius: 4px;
        height: 3rem;
        display: flex;
        align-items: center;
        line-height: 3rem;
    }

.nav-item a.active {
    background-color: rgba(255,255,255,0.25);
    color: white;
}

.nav-item a:hover {
    background-color: rgba(255,255,255,0.1);
    color: white;
}

.screen-block {
    height: 100vh;
    width: 100vw;
    background-color: #000000aa;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
}

.leftbar {
    width: 300px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 2;
}

.no-error .Mui-error {
	color: inherit !important;
}

	.no-error .Mui-error .MuiOutlinedInput-notchedOutline {
		border-color: inherit !important;
	}

@media (max-width: 1200px) {
	.desktop-page, .desktop-modals, .desktop-modals.reports {
		width: calc((100vw - 300px));
		max-width: 800px;
	}

		.desktop-page .list-header {
			width: calc((100vw - 300px) - 45px);
			max-width: 755px;
		}

		.desktop-modals .wiz, .desktop-modals.reports .wiz {
			width: calc((100vw - 300px) - 10px) !important;
			max-width: 790px;
		}

		.desktop-modals .modal-header, .desktop-modals .modal-footer {
			width: calc((100vw - 300px) - 25px) !important;
			margin-left: -15px;
			max-width: 780px;
		}
}

